import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { DataBreadcrumbItem, DataCategory } from 'data/Category';
import { LanguageLocalizations } from 'data/Language';
import { Routes } from 'utils/routes';
import NavigationLanguage from 'components/navigation/NavigationLanguage';
import NavigationSearch from 'components/navigation/NavigationSearch';
import NavigationMenu from 'components/navigation/NavigationMenu';

interface NavigationProps {
  categories: DataCategory[];
  localizations: LanguageLocalizations;
  onRender: (desktop: boolean) => void;
  breadcrumb?: DataBreadcrumbItem[];
}

function Navigation({
  categories,
  breadcrumb,
  onRender,
  localizations,
}: NavigationProps) {
  const { t } = useTranslation('common');
  const [desktop, setDesktop] = useState(true);
  const [opened, setOpened] = useState(false);

  const onReload = useCallback(
    (enabled: boolean) => {
      setDesktop(enabled);
      setOpened(false);
      onRender(enabled);
    },
    [onRender],
  );

  return (
    <div
      className={classNames('Navigation__wrapper', {
        'Navigation__wrapper--small': !desktop,
      })}
    >
      <div
        className={classNames('Navigation__helper', {
          'Navigation__helper--small': !desktop,
        })}
      />
      <div
        className={classNames('Navigation', {
          'Navigation--small': !desktop,
          'Navigation--opened': opened,
        })}
      >
        <div className="Container">
          <Link href={Routes.homepage} passHref legacyBehavior>
            <a className="Navigation__brand">{t('NAVIGATION_TITLE')}</a>
          </Link>
          <div className="Navigation__content">
            <div className="Navigation__contentHelper" />
            <div className="Navigation__breadcrumb">
              {!breadcrumb && <h1>{t('NAVIGATION_DESCRIPTION')}</h1>}
              {breadcrumb && (
                <Link href={Routes.homepage} passHref legacyBehavior>
                  <a className="Navigation__breadcrumbItem">
                    {t('NAVIGATION_HOME')}
                  </a>
                </Link>
              )}
              {breadcrumb?.map(({ title, path }, key) => {
                if (path === null || key + 1 === breadcrumb?.length) {
                  return (
                    <span
                      key={key}
                      className="Navigation__breadcrumbItem Navigation__breadcrumbItem--noLink"
                    >
                      {title}
                    </span>
                  );
                }

                return (
                  <Link key={key} href={path} passHref legacyBehavior>
                    <a className="Navigation__breadcrumbItem">{title}</a>
                  </Link>
                );
              })}
            </div>
            <button
              type="button"
              onClick={() => setOpened(!opened)}
              className="Navigation__menuButton"
            >
              Menu
            </button>
            <NavigationLanguage localizations={localizations} />
            <div className="Navigation__scroll">
              <NavigationSearch />
              <NavigationMenu categories={categories} onReload={onReload} />
            </div>
          </div>
        </div>
        <div className="Navigation__breadcrumbHelper" />
      </div>
    </div>
  );
}

export default Navigation;
